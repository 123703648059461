<template>
  <div>
    <v-data-table
      :fixed-header="false"
      :height="undefined"
      v-bind="dataTableAttrs"
      v-if="assessmentId"
      :headers="headers"
      :items="list"
      :loading="isLoading"
      :options.sync="options"
      :server-items-length="total"
      :items-per-page.sync="itemsPerPage"
      v-model="selectedItems"
      @update:items-per-page="handleItemsPerPageUpdated"
      :show-select="false"
    >
      <!-- eslint-disable-next-line -->
      <template v-slot:top="{ headers }">
        <rs-table-title-bar
          v-if="$refs.headSearch"
          class="mt-6"
          title=""
          icon=""
          @clear-filters="$refs.headSearch.reset"
          @reload="loadList"
          :search.sync="search.query"
          :edit-enabled="false"
          v-bind="titleBarAttrs"
        ></rs-table-title-bar>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:body.prepend="{ headers }">
        <rs-table-head-search
          :headers="headers"
          :search.sync="search"
          ref="headSearch"
        />
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:body.append="{ headers }">
        <rs-table-foot-totals :headers="headers" :totals="footTotals" />
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.amount="{ value }">
        <rs-table-cell-number price :value="value" />
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.deferment_amount="{ value }">
        <rs-table-cell-number price :value="value" />
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.remaining_total_amount="{ value }">
        <rs-table-cell-number price colored reverse :value="value" />
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.remaining_amount="{ value }">
        <rs-table-cell-number price :value="value" />
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.deferment_balance="{ value }">
        <rs-table-cell-number price colored reverse :value="value" />
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.debt_collected_on="{ value }">
        <rs-table-cell-date :value="value" />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { debounce } from "@/core/plugins/debounce";
import { hasDataTable, hasExport } from "@/view/mixins";

export default {
  name: "DebtList",
  mixins: [hasDataTable, hasExport],
  props: {
    assessmentId: {
      type: Number,
      required: true,
      default: null,
    },
    paidOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
    unpaidOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapGetters(["blocks", "houseTypes"]),
  },
  watch: {
    options: {
      handler() {
        this.loadList();
      },
      deep: true,
    },
    search: {
      handler: debounce(function () {
        this.loadList();
      }),
      deep: true,
    },
    assessmentId() {
      this.loadList();
    },
    paidOnly() {
      this.loadList();
    },
    unpaidOnly() {
      this.loadList();
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.assessmentId) {
        this.loadList();
      }
    });
  },
  data() {
    return {
      headers: [],
      search: {
        debt_status: null,
      },
      options: {
        sortBy: ["created_at"],
      },
      titleBarAttrs: {
        exportUrl: "income/debts",
        exportParams: this.getParams,
      },
    };
  },
  methods: {
    getParams() {
      const params = Object.assign(
        this.buildSearchParams(this.search, this.options)
      );

      params.assessment_id = this.assessmentId;

      if (this.paidOnly) {
        params.collected = 1;
      } else if (this.unpaidOnly) {
        params.collected = 0;
      }

      return params;
    },
    setHeaders() {
      const headers = [
        {
          text: "Blok",
          value: "block",
          searchable: "select",
          options: () => this.blocks,
          width: "75px",
          align: "center",
        },
        {
          text: "BB No",
          value: "door_number",
          searchable: "text",
          width: "75px",
          align: "center",
        },
        {
          text: "BB No",
          value: "door_number",
          searchable: "text",
          width: "75px",
          align: "center",
        },
        {
          text: "BB Tipi",
          value: "house_type_name",
          searchable: "multiselect",
          searchValue: "house_type_id",
          width: "75px",
          align: "center",
          options: () => this.houseTypes,
        },
        {
          text: "Sakin",
          value: "resident_name",
          searchable: "text",
        },
        {
          text: "Tahakkuk Eden",
          value: "amount",
          searchable: "number",
          align: "end",
          width: "155px",
          show: !this.paidOnly,
        },
        {
          text: "Gecikme Tahakkuk",
          value: "deferment_amount",
          searchable: "number",
          align: "end",
          show: this.unpaidOnly,
        },
        {
          text: "Toplam Tahakkuk",
          value: "total_amount",
          searchable: "number",
          align: "end",
          show: this.unpaidOnly,
        },
        {
          text: "Kalan",
          value: "remaining_total_amount",
          searchable: "number",
          align: "end",
          show: this.unpaidOnly,
        },
        {
          text: "Ana Borç Kalan",
          value: "remaining_amount",
          searchable: "number",
          align: "end",
          show: this.unpaidOnly,
        },
        {
          text: "Gecikme Bakiye",
          value: "deferment_balance",
          searchable: "number",
          align: "end",
          show: this.unpaidOnly,
        },
        {
          text: "Ödendiği Tarihi",
          value: "debt_collected_on",
          searchable: "date",
          width: "120px",
          show: this.paidOnly,
        },
        {
          text: "Banka / Kasa",
          value: "bank_safe",
          searchable: "text",
          width: "150px",
          align: "center",
          show: this.paidOnly,
        },
      ];

      this.headers = headers.filter((item) => {
        if (item.show === undefined) {
          return true;
        }

        return item.show;
      });
    },
    loadList() {
      if (this.isLoading || !this.assessmentId) {
        return;
      }

      this.setHeaders();

      this.isLoading = true;
      this.list = [];
      this.footTotals = [];

      const params = this.getParams();
      this.generateExportColumns(this.headers);

      this.$api
        .query("income/debts", { params })
        .then((response) => {
          const list = [];

          if (response.data && response.data.data) {
            for (const item of response.data.data) {
              list.push(item);
            }
          }

          this.list = list;

          if (response.data && response.data.meta) {
            this.total = response.data.meta.total;
            this.options.page = response.data.meta.current_page;
            this.itemsPerPage = response.data.meta.per_page;
            this.options.itemsPerPage = this.itemsPerPage;
            this.footTotals = response.data.meta.totals;
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>
